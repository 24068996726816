var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('a-tabs',{staticClass:"kit-tabs-bordered mb-2",attrs:{"defaultActiveKey":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"Resources"}}),_c('a-tab-pane',{key:"2",attrs:{"tab":"Activity"}}),_c('a-tab-pane',{key:"3",attrs:{"tab":"Settings"}})],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',{staticClass:"mb-4"},[_vm._v(" Automatic system-level backups. Use the backup images to revert the server or create new Droplets. Backups cost 20% of the Droplet price. ")]),_c('a',{staticClass:"btn btn-light btn-lg text-primary font-size-14 d-inline-block mb-5",attrs:{"href":"javascript: void(0);"}},[_vm._v("Enable Backups")]),_vm._m(7),_c('p',{staticClass:"mb-4"},[_vm._v(" Block storage lets you add independent storage volumes that can be accessed like local disk and moved from one Droplet to another within the same region. ")]),_c('a',{staticClass:"btn btn-light btn-lg text-primary font-size-14 d-inline-block mb-5 text-primary",attrs:{"href":"javascript: void(0);"}},[_vm._v("Add Volume")]),_vm._m(8),_c('p',{staticClass:"mb-4"},[_vm._v(" Block storage lets you add independent storage volumes that can be accessed like local disk and moved from one Droplet to another within the same region. ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('a-input',{staticClass:"width-100p mb-5",attrs:{"placeholder":"Please enter droplet hostname..."}})],1)]),_c('a',{staticClass:"btn btn-lg btn-success width-100p font-size-16",attrs:{"href":"javascript: void(0);"}},[_vm._v("Create Droplet")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap align-items-center"},[_c('div',{staticClass:"kit__utils__avatar kit__utils__avatar--size64 flex-shrink-0 mr-5 mb-3"},[_c('img',{attrs:{"src":"resources/images/avatars/2.jpg","alt":"Mary Stanform"}})]),_c('div',{staticClass:"mr-auto mb-3"},[_c('div',{staticClass:"text-dark font-weight-bold font-size-24"},[_c('span',{staticClass:"mr-3"},[_vm._v("Mediatec Software")]),_c('span',{staticClass:"align-middle text-primary text-uppercase font-size-12 badge badge-light"},[_vm._v("Default")])]),_c('div',[_vm._v("Operational / Developer tooling / Update your project information under Settings")])]),_c('a',{staticClass:"btn btn-light btn-lg text-blue font-size-14",attrs:{"href":"javascript: void(0);"}},[_vm._v("Move Resources →")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"mb-4 text-uppercase"},[_c('strong',[_vm._v("Choose an image")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-md-3 col-sm-6 col-xs-12"},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"card-header pt-3 pb-3"},[_c('div',{staticClass:"text-uppercase text-dark font-weight-bold"},[_vm._v("Ubuntu")])]),_c('div',{staticClass:"card-body pt-3 pb-3"},[_c('div',{staticClass:"text-center text-gray-5"},[_vm._v("18.04 x86")])])])]),_c('div',{staticClass:"col-md-3 col-sm-6 col-xs-12"},[_c('div',{staticClass:"card text-center bg-light border-blue"},[_c('div',{staticClass:"card-header pt-3 pb-3"},[_c('div',{staticClass:"text-uppercase text-dark font-weight-bold"},[_vm._v("Freebsd")])]),_c('div',{staticClass:"card-body pt-3 pb-3"},[_c('div',{staticClass:"text-center text-gray-5"},[_vm._v("18.04 x86")])])])]),_c('div',{staticClass:"col-md-3 col-sm-6 col-xs-12"},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"card-header pt-3 pb-3"},[_c('div',{staticClass:"text-uppercase text-dark font-weight-bold"},[_vm._v("Fedora")])]),_c('div',{staticClass:"card-body pt-3 pb-3"},[_c('div',{staticClass:"text-center text-gray-5"},[_vm._v("18.04 x86")])])])]),_c('div',{staticClass:"col-md-3 col-sm-6 col-xs-12"},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"card-header pt-3 pb-3"},[_c('div',{staticClass:"text-uppercase text-dark font-weight-bold"},[_vm._v("Debian")])]),_c('div',{staticClass:"card-body pt-3 pb-3"},[_c('div',{staticClass:"text-center text-gray-5"},[_vm._v("18.04 x86")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"mb-4 text-uppercase"},[_c('strong',[_vm._v("Choose plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card bg-light border-blue"},[_c('div',{staticClass:"card-body pt-3 pb-3"},[_c('div',{staticClass:"text-uppercase text-dark font-weight-bold"},[_vm._v("Starter")]),_c('div',{staticClass:"text-center text-blue"},[_c('div',{staticClass:"font-weight-bold font-size-24"},[_vm._v("Standard Plan")]),_c('div',[_vm._v("$0.060 /hour")])])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body pt-3 pb-3"},[_c('div',{staticClass:"text-uppercase text-dark font-weight-bold"},[_vm._v("Performance")]),_c('div',{staticClass:"text-center text-blue"},[_c('div',{staticClass:"font-weight-bold font-size-24"},[_vm._v("General Purpose")]),_c('div',[_vm._v("$0.060 /hour")])])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body pt-3 pb-3"},[_c('div',{staticClass:"text-uppercase text-dark font-weight-bold"},[_vm._v("Pro")]),_c('div',{staticClass:"text-center text-blue"},[_c('div',{staticClass:"font-weight-bold font-size-24"},[_vm._v("CPU Optimized")]),_c('div',[_vm._v("$0.060 /hour")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-5"},[_vm._v(" Each Droplet adds more free data transfer to your account, starting at 1TB/month and scaling with Droplet usage and size. Additional outbound data transfer is billed at $.01/GB. "),_c('a',{staticClass:"text-blue",attrs:{"href":"javascript: void(0);"}},[_vm._v("Read more")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"mb-4 text-uppercase"},[_c('strong',[_vm._v("Add backups")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"mb-4 text-uppercase"},[_c('strong',[_vm._v("Add block storage")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"mb-4 text-uppercase"},[_c('strong',[_vm._v("Choose a hostname")])])
}]

export { render, staticRenderFns }